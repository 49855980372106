<template>
  <v-row>
    <v-col
      cols="8"
    > 
      <div class="login-wrapper">
        <v-row>
          <v-col cols="12">
            <div class="d-flex align-center justify-center">
              <img 
                src="@/assets/logo-datakairos.svg" 
                alt="logo"
                class="mt-16 mb-6"
              >
            </div>
          </v-col>
        </v-row>
        <v-row class="login-wrapper__form">
          <v-col 
            cols="12"
            class="d-flex flex-column"
          >
            <h1 class="text-h2 cassiopee-purple--text text-capitalize login-wrapper__form-title">
              {{ title }}
            </h1>
            <p class="text-body-2 my-5"> 
              {{ subtitle }}
            </p>
          </v-col>
        </v-row>
        <slot />
      </div>
    </v-col>
    <v-col
      cols="4"
      class="cassiopee-purple"
    >
      <div class="d-flex justify-center align-center login-wrapper__logo">
        <img 
          src="@/assets/datakairos_logo_seul.svg" 
          alt="logo"
          class="mx-auto"
        >
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "BaseAuthentication",
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: ""
    }
  }
}
</script>


<style lang="scss" scoped>
@import '@/styles/variables';

.login-wrapper {
  height: 100vh;
  width: 646px;
  margin: 0 auto;

  &__form {
    &-title {
      border-bottom: 1px solid map-deep-get($colors, 'cassiopee-purple', 'base');
      padding-bottom: 16px;
    }
  
    &-divider {
      max-width: 176px;
    }
  }

  &__logo {
    height: 100%;
    width: 100%;
  }
}
</style>