<template>
  <base-authentication
    title="Mot de passe perdu"
    subtitle="Recevez votre mot de passe sur votre adresse électronique"
  >
    <form @submit.prevent="askForNewPassword">
      <v-row>
        <v-col 
          cols="12"
          class="pb-0"
        >
          <form-fields-text
            v-model="email"
            type="email"
            placeholder="prenom.nom@unif.fr"
            login-size
            required
            email
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col 
          cols="12"
          class="d-flex flex-column pt-0"
        >
          <div class="d-flex mb-3 forgot-password__caption align-center">
            <span class="font-italic subtitle-2">
              Vous avez déjà un login et un mot de passe ?
            </span>
            <router-link to="/login">
              <span class="cassiopee-purple--text mx-3 pr-9 font-italic subtitle-2">S'identifier</span>
            </router-link>
          </div>
          <base-button 
            right
            x-small
            icon="$arrowRight"
            type="submit"
            @click="redirectLoginPage"
          >
            Poursuivre
          </base-button>
        </v-col>
      </v-row>
    </form>
  </base-authentication>
</template>

<script>
import FormFieldsText from '@/components/FormFieldsText.vue'
import BaseButton from '@/components/BaseButton.vue'
import BaseAuthentication from '@/components/BaseAuthentication.vue'
import { mapActions, mapMutations } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: "ForgotPassword",
  components: {
    FormFieldsText,
    BaseButton,
    BaseAuthentication
  },
  computed: mapFields('authentication', ['passwordResetForm.email']),
  methods: {
    ...mapActions('authentication', ['requestPasswordReset']),
    ...mapMutations(['setAlert']),
    askForNewPassword() {
      this.requestPasswordReset().finally(() => {
        this.setAlert({
          show: true,
          text: "Si vous disposez d'un compte sur cette plateforme, vous allez recevoir un email de réinitialisation de votre mot de passe",
        });
        this.$router.push('/login');
      });
    },
  },
};
</script>