<template>
  <base-form-field 
    :label="fieldLabel" 
    :required="required"
    :centered="centered"
    :commentaire="commentaire"
    v-bind="$attrs"
    v-on="$listeners"
  > 
    <v-text-field
      :placeholder="placeholder"
      :suffix="inputSuffix"
      single-line
      :class="{ 'form-input__littleSize': littleSize, 'form-input__middleSize': middleSize, 'form-input__alignRight': inputSuffix}"
      class="form-input text-subtitle-2"
      color="cassiopee-purple"
      outlined
      :rules="selectedRules"
      :hint="fieldDescription"
      persistent-hint
      :success="validateValue"
      v-bind="$attrs"
      :append-outer-icon="icon"
      v-on="$listeners"
    />
  </base-form-field>
</template>

<script>
import validateField from "@/mixins/validateField.js"
import BaseFormField from '@/components/BaseFormField.vue'

export default {
  name: "FormFieldsText",
  components: { 
    BaseFormField,
  },
  mixins: [validateField],
  props: {  
    placeholder: {
      type: String,
      default: ""
    },
    inputSuffix: {
      type: String,
      default: "",
    },
    fieldLabel: {
      type: String,
      default: "",
    },
    fieldDescription: {
      type: String,
      default: ""
    },
    littleSize: {
      type: Boolean,
      default: false,
    },
    middleSize: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false
    },
    minMax: {
      type: Boolean,
      default: false,
    },
    minMaxUtilisateur: {
      type: Boolean,
      default: false,
    },
    date: {
      type: Boolean,
      default: false,
    },
    numberInteger: {
      type: Boolean,
      default: false,
    },
    numberDecimal: {
      type: Boolean,
      default: false,
    },
    email: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: undefined,
    },
    iconColor: {
      type: String,
      default: 'cassiopee-purple',
    },
    centered: {
      type: Boolean,
      default: false,
    },
    commentaire: {
      type: String,
      default: "",
    },
    positive: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.form-input {
  &__littleSize {
    max-width: 280px !important;
  }

  &__middleSize {
    max-width: 510px !important;
  }

  &__alignRight input {
    text-align: right !important;
  }
}
</style>