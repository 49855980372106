<template>
  <v-btn
    class="my-2 px-6 py-4"
    :class="{ 'disabled': disabled }"
    :color="color"
    :height="height"
    v-bind="$attrs"
    :outlined="outlined"
    dark
    :disabled="disabled"
    v-on="$listeners"
  >
    <v-icon
      v-if="icon && !right"
      class="mx-4"
      v-bind="$attrs"
      :color="iconColor || (outlined ? 'cassiopee-purple' : 'white')"
    >
      {{ icon }}
    </v-icon>
    <span class="text-button"><slot /></span>
    <v-icon
      v-if="right"
      class="mx-4"
      v-bind="$attrs"
      :color="iconColor || (outlined ? 'cassiopee-purple' : 'white')"
    >
      {{ icon }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    icon: {
      type: String,
      default: undefined,
    },
    color: {
      type: String,
      default: 'cassiopee-purple',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    iconColor: {
      type: String,
      default: undefined,
    },
    height: {
      type: String,
      default: '50px',
    },
    right: {
      type: Boolean,
      default: false,
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/variables';
.disabled.v-btn.v-btn--disabled.v-btn--has-bg {
  opacity: .5;
  background-color: map-deep-get($colors, 'cassiopee-purple', 'base') !important;
  color: white !important;
}
</style>