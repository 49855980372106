export default {
  data () {
    return {
      rules: {
        minmax: value => (value >= 0 && value <= 20000) || 'La valeur doit être comprise entre 0 et 20.000',
        minmaxutilisateur: value => (value >= 0 && value <= 100000) || 'La valeur doit être comprise entre 0 et 100.000',
        required: value => !!value || 'Le champ est obligatoire',
        date: value => (value >= 2021 && value <= 2030) || 'L\'échelle démarre en 2021 et peut aller jusqu\'à 2030. ',
        integer: value =>  Number.isInteger(Number(value)) || 'La valeur de ce champ doit être un entier',
        decimal: value => (Number.isInteger(Number(value)) || (value % 1 != 0))  || 'La valeur de ce champ doit être un nombre',
        email: value => {
          const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return emailPattern.test(value) || 'Veuillez renseigner un email valide'
        },
        maxLength: value => (value.length <= 5) || 'Veuillez sélectionner 5 services maximum.',
        positive: value => value >= 0 || 'La valeur doit être positive',
      }
    }
  },
  computed: {
    validateValue() {
      const val = this.$attrs.value;
      return (val !== '' && val !== null && val !== undefined && val != 0)
    },
    selectedRules() {
      const result = [];
      if (this.minMax) {
        result.push(this.rules.minmax);
      }
      if (this.minMaxUtilisateur) {
        result.push(this.rules.minmaxutilisateur);
      }
      if (this.required) {
        result.push(this.rules.required);
      }
      if (this.date) {
        result.push(this.rules.date);
      }
      if (this.numberInteger) {
        result.push(this.rules.integer);
      }
      if (this.numberDecimal) {
        result.push(this.rules.decimal);
      }
      if (this.email) {
        result.push(this.rules.email);
      }
      if (this.maxLength) {
        result.push(this.rules.maxLength)
      }
      return result;
    }
  }
}